<!-- #################################################################################### -->
<!-- ###### HERINCO                                                                ###### -->
<!-- ###### @author: John David Vásquez Serna                                      ###### -->
<!-- ###### @date: Enero 2025                                                      ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
  <div class="contenido">
    <!-- Descripción -->
    <div class="d-flex align-center mb-3">
      <v-icon color="blue darken-4" size="55">library_books</v-icon>
      <span class="ms-4 descripcion">
        Herramienta diseñada para generar reportes en formato TXT
        <br> para la radicación de facturas de Nueva EPS.
      </span>
    </div>
    <v-divider color="#9E9E9" class="mb-4"></v-divider>

    <!-- Filtros para descargar un archivo -->
    <section>
      <v-container class="container">

        <v-row>
          <v-col cols="12" sm="12" md="12" class="pa-0">
            <span class="text-h6 text-center pa-2 pt-0">Estructura del archivo</span>
            <v-card class="pa-2" outlined>
              <v-card-text class="pa-2">
                <v-row>
                  <v-col cols="6" sm="8" md="8" class="pa-2">
                    <span class="text-subtitle-2 font-weight-bold black--text">NO PBS</span> <br>
                    <span> Código de factura; Número de factura; Prefijo; Código del producto; ID reporte entrega; Identificador del reporte del suministro; Identificador de facturación </span>
                  </v-col>
                  <v-col cols="6" sm="4" md="4" class="pa-2">
                    <span class="text-subtitle-2 font-weight-bold black--text">PBS</span> <br>
                    <span> Código de factura; Número de factura; Prefijo </span>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="12" md="12" align="center" class="d-flex justify-center">
            <span class="pr-3">Tipo de archivo: </span>
            <v-radio-group v-model="filtro.tipoArchivo" row hide-details class="ma-0 pa-0 d-flex align-center">
              <v-radio :readonly="leyendoArchivo" label="NO PBS" value="N"></v-radio> 
              <v-radio :readonly="leyendoArchivo" label="PBS" value="P"></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="12" md="12" align="center" class="pa-0 pb-4 d-flex justify-center">
            <div class="archivo">
              <div>
                <input :disabled="filtro.tipoArchivo === null || filtro.tipoArchivo === '' || leyendoArchivo" type="file" 
                  class="seleccionarArchivo" accept=".csv" @change="lectorCSV($event)" ref="cargaDeRelaciones">
              </div>
              <p v-if="filtro.tipoArchivo === null || filtro.tipoArchivo === ''" style="padding-top: 0.7rem;">
                Primero debe seleccionar un tipo de archivo.
              </p>
              <p v-else-if="validandoDocumento" style="padding-top: 0.6rem;">
                Generando reporte, espere un momento...
                <v-icon :class="{ 'rotate-animation': validandoDocumento }"
                  large>rotate_right
                </v-icon>
              </p>
              <p v-else-if="formatoIncorrecto" style="color: red; margin-top: 1.4rem;">
                <v-icon class="mr-2" style="color: red;">error</v-icon>
                {{ mensajeIncorrecto }}
              </p>
              <p v-else>Arrastre un archivo .CSV con los datos de la factura,
                <br>
                También puede hacer click en este cuadro.
              </p>
            </div>
          </v-col>
        </v-row>

        <!-- <v-row> -->
          <!-- Campo para escribir el número de documento -->
          <!-- <v-col cols="12" sm="3" md="3" class="pa-0">
            <v-text-field ref="filtroCodigo" v-model="filtro.codigo" outlined dense label="Código de factura" 
              class="pr-1 pl-1" :rules="rules.agregarRules" :disabled="inProgress === true"
              @keypress="validarLetra" @paste="handlePaste($event, 'codigo')" maxlength="4">
            </v-text-field>
          </v-col> -->

          <!-- Campo para escribir el número de la factura -->
          <!-- <v-col cols="12" sm="3" md="3" class="pa-0">
            <v-text-field ref="filtroNumero" v-model="filtro.numero" outlined dense label="Número de factura" 
              class="pr-1 pl-1" :rules="rules.agregarRules" :disabled="inProgress === true"
              @keypress="validarLetra" @paste="handlePaste($event, 'numero')" maxlength="9">
            </v-text-field>
          </v-col> -->

          <!-- Campo para escribir el prefijo de la factura -->
          <!-- <v-col cols="12" sm="3" md="3" class="pa-0">
            <v-text-field ref="filtroPrefijo" v-model="filtro.prefijo" outlined dense label="Prefijo de factura" 
              class="pr-1 pl-1" :rules="rules.agregarRules" :disabled="inProgress === true" maxlength="4">
            </v-text-field>
          </v-col> -->

          <!-- Campo para seleccionar el tipo de archivo PSB o no PBS -->
          <!-- <v-col cols="12" sm="3" md="3" class="pa-0">
            <v-select ref="filtroArchivo" v-model="filtro.tipoArchivo" label="Tipo de archivo" 
              outlined dense :items="tiposArchivos" :menu-props="{ offsetY: true}"
              @keydown="tabulador($event, 'tipoArchivo', 'filtroArchivo')" 
              :disabled="inProgress === true" :rules="rules.agregarRules" class="pr-1 pl-1">
            </v-select>
          </v-col> -->

        <!-- </v-row> -->
      </v-container>

      <!-- Botón de generar reporte -->
      <!-- <v-sheet class="d-flex align-center justify-center" :color="disableReport ? 'grey lighten-4' : 'green lighten-5'"
        height="65px" width="100%" rounded>
        <v-btn v-if="inProgress === false" color="success" @click="generarRadicacion()" :disabled="disableReport">
          <v-icon>download</v-icon>
          generar reporte
        </v-btn>
        <div v-else class="d-flex justify-center">
          <v-icon class="rotate-animation" color="#0d47a1" large>rotate_right</v-icon>
          <span class="d-flex align-center font-weight-black primary--text ms-2">CARGANDO</span>
        </div>
      </v-sheet> -->
    </section>

    <!-- Alerta cuando no se encuentran resultados -->
    <v-alert class="mt-5" icon="error" color="error" transition="scale-transition" :value="alert" outlined>
      <h3>No se encontraron resultados.</h3>
      <p class="no-resultados">Los datos ingresados no coinciden con ningún registro.
        <br>Verifica que los filtros sean correctos o intenta una búsqueda diferente.
      </p>
    </v-alert>

    <!-- Contenedor para mostrar los mensajes de error -->
    <div v-if="mostrarNotificacion" :class="['notificacion', tipoNotificacion]">
      <span><v-icon :color="tipoNotificacionColor" :class="iconoNotificacionClase"
          class="rotate-animation-notification size">{{ iconoNotificacion }}</v-icon></span>
      <span>{{ mensajeNotificacion }}</span>
    </div>

  </div>
</template>
<!-- #################################################################################### -->
<!-- ###### Sección de Scripts                                                     ###### -->
<!-- #################################################################################### -->
<script>
import { mapState } from "vuex";
import { Role } from "@/router/role.js";
import Papa from 'papaparse';

export default {
  name: "RadicacionFactura",
  data() {
    return {
      filtro: {
        codigo: null,
        numero: null,
        prefijo: null,
        tipoArchivo: null,
      },
      mensajeError: '',
      alert: false,
      inProgress: false,
      userRoles: {},
      roles: Role,
      rules: {
        agregarRules: [
          v => !!v || "Este campo es requerido.",
        ],
      },
      tiposArchivos: [
        { text: 'PBS', value: 'P' },
        { text: 'NO PBS', value: 'N' }
      ],
      mostrarNotificacion: false,
      mensajeNotificacion: "",
      tipoNotificacion: "",
      tipoNotificacionColor: "",
      iconoNotificacion: "",
      iconoNotificacionClase: "",
      leyendoArchivo: false,
      validandoDocumento: false,
      formatoIncorrecto: false,
      mensajeIncorrecto: '',
      registros: [],
      archivoRadicacionFactura: {
        radicacionFactura: [],
      }
    }
  },
  created() {
    this.userRoles = this.auth.roles;
  },
  computed: {
    ...mapState(["auth", "enterprise"]),
    /** Función usada para deshabilitar o habilitar el botón para generar un reporte de acuerdo a las propiedades de los filtros. */
    disableReport() {
      const tipArc = this.filtro.tipoArchivo === null ? '' : this.filtro.tipoArchivo;
      const cod = this.filtro.codigo === null ? '' : this.filtro.codigo;
      const num = this.filtro.numero === null ? '' : this.filtro.numero;     
      const pre = this.filtro.prefijo === null ? '' : this.filtro.prefijo;
      return tipArc === "" || cod === "" || num === "" || pre === "" || this.alert === true;
    },
  },
  watch: {
    /**
     * Este watcher se activa cuando alguna propiedad del objeto `filtro` cambia.
     * Es "deep", por lo que observa cambios en propiedades anidadas dentro de `filtro`.
     * Al detectar cambios, se encarga de desactivar la alerta (`alert` se establece en false).
     */
    filtro: {
      deep: true,
      handler() {
        this.alert = false;
      }
    },
  },
  methods: {

   /**
     * Maneja la carga de un archivo CSV, validando su formato y contenido según el tipo de archivo seleccionado.
     * 
     * @param {Event} event - El evento generado al seleccionar o arrastrar un archivo.
     * - Valida que el tipo de archivo sea correcto (CSV) y que contenga datos.
     * - Procesa los datos del archivo en función del tipo de filtro seleccionado ('P' o 'N').
     * - Llama a métodos específicos para procesar el contenido en el backend.
     * - Maneja errores relacionados con la lectura y el formato del archivo.
     */
    lectorCSV(event) {
      this.leyendoArchivo = true;
      this.formatoIncorrecto = false;
      this.mensajeIncorrecto = '';

      if (this.filtro.tipoArchivo !== 'P' && this.filtro.tipoArchivo !== 'N') {
        this.formatoIncorrecto = true;
        this.mensajeIncorrecto = 'Primero debe seleccionar un tipo de archivo.';
        this.leyendoArchivo = false;
        return;
      }

      const file = event.target.files[0];
      if (!file || file.name.split('.').pop() !== 'csv') {
        this.formatoIncorrecto = true;
        this.mensajeIncorrecto = 'Formato incorrecto, debe subir o arrastrar un archivo .csv.';
        this.leyendoArchivo = false;
        return;
      }

      this.validandoDocumento = true;
      
      const reader = new FileReader();
      reader.onload = (e) => {
        const content = e.target.result;
        const parsedData = Papa.parse(content, {
          header: false,
          delimiter: ';',
          encoding: 'ISO-8859-1',
        });

        if (!parsedData.data || parsedData.data.length === 0 || (parsedData.data.length === 1 && parsedData.data[0].length === 1 && parsedData.data[0][0] === '')) {
          this.formatoIncorrecto = true;
          this.mensajeIncorrecto = 'El archivo está vacío. Por favor, suba un archivo con contenido.';
          this.validandoDocumento = false;
          this.leyendoArchivo = false;
          return;
        }

        const registros = [];
        const headerRow = parsedData.data[0];
        const isHeaderRow = /\d/.test(headerRow[0]) && /\d/.test(headerRow[1]);

        for (let index = isHeaderRow ? 0 : 1; index < parsedData.data.length - 1; index++) {
          const row = parsedData.data[index];
          if (this.filtro.tipoArchivo === 'P') {
            if (row[0] !== '' || row[1] !== '') {
              registros.push({
                codDocu: row[0],
                numDocu: row[1],
                abrDocu: row[2],
              });
            }
          } else {
            if (row[0] !== '' || row[1] !== '') {
              registros.push({
                codDocu: row[0],
                numDocu: row[1],
                abrDocu: row[2],
                codProd: row[3],
                idRepo: row[4],
                ideRep: row[5],
                ideFac: row[6],
              });
            }
          }
        }

        if (registros.length === 0) {
          this.formatoIncorrecto = true;
          this.mensajeIncorrecto = 'El archivo está vacío. Por favor, suba un archivo con contenido.';
          this.validandoDocumento = false;
          this.leyendoArchivo = false;
          return;
        }

        const registrosAgrupados = this.agruparRegistros(registros);

        const backendCalls = [
          this.filtro.tipoArchivo === 'P' ? this.procesarArchivoPbs(registrosAgrupados) : this.procesarArchivoNoPbs(registrosAgrupados),
        ];

        Promise.all(backendCalls)
          .then(() => {
            this.$nextTick(() => {
              this.descargarReporte();
            });
          })
          .catch((error) => {
            this.manejarError(error);
            this.validandoDocumento = false;
            this.leyendoArchivo = false;
          });
      };

      reader.onerror = () => {
        this.formatoIncorrecto = true;
        this.mensajeIncorrecto = 'Error al leer el archivo. Verifique el contenido e inténtelo de nuevo.';
        this.validandoDocumento = false;
        this.leyendoArchivo = false;
      };

      reader.readAsText(file);
    },
    /**
     * Agrupa los registros por codDocu;numDocu;abrDocu;
     * @param registros - registros en el archivo .csv.
     */
    agruparRegistros(registros) {
      const agrupados = {};

      if (this.filtro.tipoArchivo === 'P') {
        registros.forEach(registro => {
          const clave = `${registro.codDocu}-${registro.numDocu}-${registro.abrDocu}`;

          if (!agrupados[clave]) {
            agrupados[clave] = {
              codDocu: registro.codDocu,
              numDocu: registro.numDocu,
              abrDocu: registro.abrDocu,
            };
          }
        });
      } else {
        registros.forEach(registro => {
          const clave = `${registro.codDocu}-${registro.numDocu}-${registro.abrDocu}`;

          if (!agrupados[clave]) {
            agrupados[clave] = {
              codDocu: registro.codDocu,
              numDocu: registro.numDocu,
              abrDocu: registro.abrDocu,
              codProd: [],
              idRepo: [],
              ideRep: [],
              ideFac: []
            };
          }

          let codProd = registro.codProd !== undefined && registro.codProd !== null && registro.codProd !== '' ? registro.codProd.trim() : 0;
          let idRepo = registro.idRepo !== undefined && registro.idRepo !== null && registro.idRepo !== '' ? registro.idRepo.trim().padStart(19, '0') : '0000000000000000000';
          let ideRep = registro.ideRep !== undefined && registro.ideRep !== null && registro.ideRep !== '' ? registro.ideRep.trim().padStart(19, '0') : '0000000000000000000';
          let ideFac = registro.ideFac !== undefined && registro.ideFac !== null && registro.ideFac !== '' ? registro.ideFac.trim().padStart(19, '0') : '0000000000000000000';

          agrupados[clave].codProd.push(Number(codProd));
          agrupados[clave].idRepo.push(idRepo);
          agrupados[clave].ideRep.push(ideRep);
          agrupados[clave].ideFac.push(ideFac);
        });
      }

      return Object.values(agrupados);
    },
    /**
      * Procesa los registros del archivo PBS enviando solicitudes al backend.
      * 
      * @param {Array} registros - Lista de registros extraídos del archivo CSV.
      * - Valida y transforma los datos de cada registro.
      * - Envía solicitudes al backend para obtener la información de las facturas.
      * - Almacena los resultados obtenidos en el objeto `archivoRadicacionFactura`.
      * - Maneja errores individuales de las solicitudes.
      * 
      * @returns {Promise} - Promesa que se resuelve cuando se procesan todos los registros.
      */
    async procesarArchivoPbs(registros) {
      const batchSize = 25; 
      const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

      const processBatch = async (batch) => {
        const promises = batch.map((registro) => {
          let codDocu = registro.codDocu && registro.codDocu.length < 5 ? registro.codDocu.trim() : null;
          let numDocu = registro.numDocu && registro.numDocu.length < 10 ? registro.numDocu.trim() : null;
          let abrDocu = registro.abrDocu && registro.abrDocu.length < 5 ? registro.abrDocu.trim() : null;

          if (codDocu && numDocu && abrDocu) {
            const data = {
              codEmpr: this.enterprise.code,
              tipArch: this.filtro.tipoArchivo,
              codDocu: Number(codDocu),
              numDocu: Number(numDocu),
              prefijo: abrDocu.toUpperCase(),
              codProd: [],
              idRepo: [],
              ideRep: [],
              ideFac: [],
            };

            return this.$http.post(`msa-reports/api/radicacion-factura/data`, data)
              .then(response => {
                if (response.data && Array.isArray(response.data) && response.data.length > 0) {
                  response.data.forEach((factura) => {
                    this.archivoRadicacionFactura.radicacionFactura.push({
                      cabecera: factura.cabecera || null,
                      detalle: factura.detalle || [],
                    });
                  });
                }
              })
              .catch(error => {
                this.manejarError(error);
                return Promise.reject(error);
              });
          } else {
            return Promise.resolve();
          }
        });

        return Promise.all(promises);
      };

      for (let i = 0; i < registros.length; i += batchSize) {
        const batch = registros.slice(i, i + batchSize);
        await processBatch(batch);
        await delay(500); 
      }
    },
    /**
     * Procesa los registros del archivo No PBS enviando solicitudes al backend.
     * 
     * @param {Array} registros - Lista de registros extraídos del archivo CSV.
     * - Valida y transforma los datos de cada registro, incluyendo campos específicos como `codProd`, `idRepo`, e `ideRep`.
     * - Envía solicitudes al backend para obtener la información de las facturas.
     * - Almacena los resultados obtenidos en el objeto `archivoRadicacionFactura`.
     * - Maneja errores individuales de las solicitudes.
     * 
     * @returns {Promise} - Promesa que se resuelve cuando se procesan todos los registros.
     */
    async procesarArchivoNoPbs(registros) {
      const batchSize = 25; 
      const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

      const processBatch = async (batch) => {
        const promises = batch.map((registro) => {
          let codDocu = registro.codDocu !== undefined && registro.codDocu !== null && registro.codDocu !== '' && registro.codDocu.length < 5 ? registro.codDocu.trim() : null;  
          let numDocu = registro.numDocu !== undefined && registro.numDocu !== null && registro.numDocu !== '' && registro.numDocu.length < 10 ? registro.numDocu.trim() : null;
          let abrDocu = registro.abrDocu !== undefined && registro.abrDocu !== null && registro.abrDocu !== '' && registro.abrDocu.length < 5 ? registro.abrDocu.trim() : null;
      
          if (codDocu !== null && numDocu !== null && abrDocu !== null) {
            const data = {
              codEmpr: this.enterprise.code,
              tipArch: this.filtro.tipoArchivo,
              codDocu: Number(codDocu),
              numDocu: Number(numDocu),
              prefijo: abrDocu.toUpperCase(),
              codProd: registro.codProd,
              idRepo: registro.idRepo,
              ideRep: registro.ideRep,
              ideFac: registro.ideFac,
            }

            return this.$http.post(`msa-reports/api/radicacion-factura/data`, data)
            .then(response => {
              if (response.data && Array.isArray(response.data) && response.data.length > 0) {
                response.data.forEach((factura) => {
                  this.archivoRadicacionFactura.radicacionFactura.push({
                    cabecera: factura.cabecera || null,
                    detalle: factura.detalle || [],
                  });
                });
              } 
            })
            .catch(error => {
              this.manejarError(error);
              return Promise.reject(error);
            });
          } else {
            return Promise.resolve();
          }
        });

        return Promise.all(promises);
      };

      for (let i = 0; i < registros.length; i += batchSize) {
        const batch = registros.slice(i, i + batchSize);
        await processBatch(batch);
        await delay(500); 
      }
    },
    /**
     * Descarga el reporte generado basado en los datos procesados del archivo cargado.
     * 
     * - Verifica si los datos de `archivoRadicacionFactura` son válidos y si contienen información.
     * - Envía los datos al backend para generar un archivo de texto (.txt).
     * - Construye el nombre del archivo dinámicamente según el tipo de archivo y la fecha actual.
     * - Descarga el archivo generado y limpia los datos locales.
     * - Muestra un mensaje de alerta si no se generan datos o maneja los errores en caso de fallos.
     */
    descargarReporte() {
      if (this.archivoRadicacionFactura && Array.isArray(this.archivoRadicacionFactura.radicacionFactura)) {
        const payload = JSON.parse(JSON.stringify(this.archivoRadicacionFactura));
        this.$http.post(`msa-reports/api/radicacion-factura/txt`, payload)
        .then(response => {
          let archivoNombre = "";
          if (response.data.length > 0) {
            const currentDate = new Date();
            const options = { month: 'long', year: 'numeric' };
            const dateFormat = new Intl.DateTimeFormat('es-ES', options).format(currentDate);
            const [month, year] = dateFormat.replace(' de ', ' ').split(' ');
            const nit = '890985122';
            archivoNombre = this.filtro.tipoArchivo === 'P' ? `AMBCOHAN_${nit}_${month.toUpperCase()}${year}_1.txt` : `COHAN_${nit}_${month.toUpperCase()}${year}_1.txt`;
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', archivoNombre);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            this.clean();
          } else {
            this.alert = true;
            archivoNombre = "";
          }
          this.inProgress = false;
        }).catch(error => {
          this.manejarError(error);
          return Promise.reject(error);
        })
      } else {
        this.formatoIncorrecto = true;
        this.mensajeIncorrecto = 'Los datos en el archivo no coinciden con ningún registro. Verifique el contenido e inténtelo de nuevo.';
      }
      this.validandoDocumento = false;
      this.leyendoArchivo = false;
      this.archivoRadicacionFactura = {
        radicacionFactura: [],
      }
    },
    /**
     * Genera y descarga el archivo de radicación de facturas en formato `.txt`.
     */
    generarRadicacion() {
      this.inProgress = true;
      this.$http.get(`msa-reports/api/radicacion-factura/list`, {
        params: {
          codEmpr: this.enterprise.code,
          codDocu: Number(this.filtro.codigo),
          numDocu: Number(this.filtro.numero),
          prefijo: this.filtro.prefijo.toUpperCase(),
          tipArch: this.filtro.tipoArchivo,
        }
      })
      .then(response => {
        let archivoNombre = "";
        if (response.data.length > 0) {
          const currentDate = new Date();
          const options = { month: 'long', year: 'numeric' };
          const dateFormat = new Intl.DateTimeFormat('es-ES', options).format(currentDate);
          const [month, year] = dateFormat.replace(' de ', ' ').split(' ');
          const nit = '890985122';
          archivoNombre = this.filtro.tipoArchivo === 'P' ? `AMBCOHAN_${nit}_${month.toUpperCase()}${year}_1.txt` : `COHAN_${nit}_${month.toUpperCase()}${year}_1.txt`;
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', archivoNombre);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          this.clean();
        } else {
          this.alert = true;
          archivoNombre = "";
        }
        this.inProgress = false;
      }).catch(error => {
        this.manejarError(error);
      })
    },
    /**
     * Valida que la tecla presionada sea un número.
     * Evita la entrada de caracteres no numéricos.
     * @param {Event} event - Evento de tecla presionada.
     */
    validarLetra(event) {
      const teclaPresionada = event.key
      if (teclaPresionada.match(/[^0-9]/)) {
        event.preventDefault();
      }
    },
    /**
     * Maneja el evento de pegado en un campo de entrada, permitiendo solo valores numéricos.
     * @param {Event} event El evento de pegado.
     */
    handlePaste(event, fieldName) {
      event.preventDefault();
      const clipboardData = event.clipboardData || window.clipboardData;
      const pastedData = clipboardData.getData('text');
      if (!/^\d+$/.test(pastedData)) {
        return;
      }
      const maxLengths = {
        codigo: 4,
        numero: 9,
      };
      const maxLength = maxLengths[fieldName];
      if (!maxLength) {
        this.filtro[fieldName] = pastedData;
        return;
      }
      const truncatedValue = pastedData.substring(0, maxLength);
      this.filtro[fieldName] = truncatedValue;
    },
    /**
     * Maneja el evento de presionar la tecla Tab para campos v-autocomplete.
     * Si se presiona la tecla Tab y el valor actual del modelo es null,
     * desenfoca el campo y establece el valor del modelo como una cadena vacía.
     *
     * @param {KeyboardEvent} event - El objeto del evento de teclado.
     * @param {string} model - El nombre de la propiedad del modelo.
     * @param {string} ref - El nombre de la referencia del campo v-autocomplete.
     */
    tabulador(event, model, ref) {
      if (event.key === 'Tab' && this.filtro[model] === null) {
        this.$refs[ref].blur();
        this.filtro[model] = '';
      }
    },
    /**
     * Limpia y reinicia el estado del formulario y sus validaciones.
     * Este método resetea las validaciones del formulario mediante el observer de VeeValidate.
     * Además, se encarga de limpiar las fechas seleccionadas, el tipo de transacción, el NIT de la transportadora y 
     * cualquier mensaje de error asociado a las fechas.
     */
    clean() {
      this.filtro.codigo = null;
      this.filtro.numero = null;
      this.filtro.prefijo = null;
      this.filtro.tipoArchivo = null;
      this.$refs.filtroCodigo?.reset();
      this.$refs.filtroNumero?.reset();
      this.$refs.filtroPrefijo?.reset();
      this.$refs.filtroArchivo?.reset();
      this.alert = false;
      if (this.$refs.cargaDeRelaciones) {
        this.$refs.cargaDeRelaciones.value = '';
      }
    },
    /**
     * Muestra una notificación global en el componente.
     *
     * @param {string} mensaje - Mensaje que se mostrará en la notificación.
     * @param {string} tipo - Tipo de la notificación (por defecto, "error").
     * @param {string} icono - Icono de la notificación (por defecto, "highlight_off").
     */
    mostrarNotificacionGlobal(mensaje, tipo, icono) {
      this.mostrarNotificacion = true;
      this.tipoNotificacion = tipo || "advertencia"; // Tipo predeterminado es "error"
      this.tipoNotificacionColor = this.obtenerColorNotificacion(this.tipoNotificacion);
      this.iconoNotificacion = icono || "highlight_off"; // Icono predeterminado es "highlight_off"
      this.mensajeNotificacion = mensaje;
      this.iconoNotificacionClase = this.obtenerClaseIcono(this.tipoNotificacion);

      // Cierra la notificación después de 5 segundos
      setTimeout(() => {
        this.cerrarNotificacion();
      }, 5000);
    },
    /**
     * Cierra la notificación
     */
    cerrarNotificacion() {
      this.mostrarNotificacion = false;
      this.mensajeNotificacion = "";
      this.tipoNotificacion = "";
    },
    /**
     * Obtiene el color correspondiente al tipo de notificación.
     * 
     * @param {string} tipo - Tipo de notificación.
     * @returns {string} - Color de la notificación.
     */
    obtenerColorNotificacion(tipo) {
      switch (tipo) {
        case "advertencia":
          return "#f80505";
        default:
          return "#f80505";
      }
    },
    /**
     * Obtiene la clase de icono correspondiente al tipo de notificación.
     * 
     * @param {*} tipo  - Tipo de notificación.
     * @returns {string} - Clase de icono.
     */
    obtenerClaseIcono(tipo) {
      switch (tipo) {
        case "advertencia":
          return "advertencia-icon";
        default:
          return "advertencia-icon";
      }
    },
    /**
     * Maneja errores y muestra notificaciones correspondientes.
     * 
     * @param {*} error - Objeto de error.
     */
    manejarError(error) {
      let mensajeError = "Error inesperado, contacta con el administrador.";
      if (error.response) {
        if (error.response.data && error.response.data.message) {
          mensajeError = error.response.data.message;
        } else if (error.response.data) {
          mensajeError = JSON.stringify(error.response.data);
        }
      }

      const maxLength = 200;
      if (mensajeError.length > maxLength) {
        mensajeError = mensajeError.substring(0, maxLength) + "...";
      }

      this.mostrarNotificacionGlobal(mensajeError, "advertencia", "highlight_off");
      this.inProgress = false;
    },
  }
}
</script>
<!-- #################################################################################### -->
<!-- ###### Sección de Estilos                                                     ###### -->
<!-- #################################################################################### -->
<style scoped>
.contenido {
  padding: 1rem;
  width: 100%;
  height: 85vh;
}

.descripcion {
  color: rgb(64, 62, 62);
}

.no-resultados {
  font-size: 15px;
  margin: 0;
}

.rotate-animation {
  animation: rotate 2s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.container {
  max-width: none !important;
  width: 100% !important;
}

.notificacion {
  position: fixed;
  top: 50px;
  right: 20px;
  padding: 15px;
  border-radius: 5px;
  z-index: 9999;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: opacity 0.5s ease-in-out;
  width: 30vw;
}

.notificacion span:last-child {
  cursor: pointer;
  margin-right: 10px;
  padding-top: 3px;
  width: 80%;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
}

.advertencia {
  background-color: #ffffffd7 !important;
  color: #f80505;
}

.advertencia-icon {
  color: #f80505;
}

.rotate-animation-notification {
  animation: rotate-notification 1s ease-in-out; 
}

@keyframes rotate-notification  {
  0% {
    transform: rotateX(180deg);
  }

  50% {
    transform: rotateX(0deg);
  }

  100% {
    transform: rotateX(180deg);
  }
}

.size {
  font-size: xxx-large;
  font-weight: bold;
}

.archivo {
  outline: 2px dashed grey;
  outline-offset: -10px;
  background: lightcyan;
  color: dimgray;
  width: 100%;
  padding: 1rem;
  height: 100px;
  position: relative;
  cursor: pointer;
}
.archivo p {
  margin-top: .7rem;
  text-align: center;
}
.archivo >div {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.seleccionarArchivo {
  display: flex;
  justify-content: center;
  opacity: 0;
  width: 100%;
  height: 100px;
  position: absolute;
  cursor: pointer;
}
.seleccionarArchivo:disabled {
  cursor: default;
}
</style>